footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;

  background-color: #f2f5f0;
  width: 100%;
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    margin: auto;
    background-color: white;
    z-index: 100;
  }
  color: black;
}
.social-media {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 60px;

  .fa {
    font-size: 35px;
    color: var(--color-primary);
  }
  .fa-youtube {
    color: var(--color-primary);

    color: #ff0000;
  }
  .fa-facebook {
    color: #3b5998;
  }
  .fa-instagram {
    background: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 25%;
  }
  .fa-tiktok {
    color: #111111;
    filter: drop-shadow(2px 0px 0px #fd3e3e) drop-shadow(-2px -2px 0px #4de8f4);
  }
}
