.navbar {
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 50px rgba(var(--color-primary), 0.9);
  border: 2px solid rgba(var(--color-primary), 0.9);
}

.navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  display: inline-block;
  padding-top: 24px;
  margin: 0 3px;
  padding-bottom: 24px;
}
.nav-item a {
  text-decoration: none;
  font-weight: 900;
  padding: 20px;
  text-decoration: none;
  color: var(--color-primary);
}

.nav-item a:not(.logo-link):hover {
  background: var(--color-primary);
  color: white;
  color: var(--color-secondary);
}
.active:not(.logo-link) {
  background: var(--color-primary);
  color: white;
  color: var(--color-secondary);
}
.logo {
  width: 40%;
  border-radius: 2px;
}

select, select:focus, select:active {
  border: 2px solid var(--color-primary);
  outline:0px solid var(--color-primary);

}