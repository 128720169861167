/* === GENERAL === */
*,
a::after,
a::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  font-size: 62.25%;
  scroll-behavior: smooth;
}

:root {
  --font-family-body: "Roboto", sans-serif;
  --color-primary: #c75723;
  --color-secondary: #fef000;
  --color-primary-lighter: #794aa2;
  --color-primary-lighter: #002f6c;
  --color-text: #525c65;
  --background-body: rgba(226, 214, 214, 0.1);
  --font-size-regular-text: 18px;
  --font-size-heading: 26px;
}

body {
  line-height: 1.5;
  font-family: var(--font-family-body);
  font-size: 18px;
  overflow-x: hidden;
  line-height: 150%;
  margin: 0;
}
#root {
  position: relative;
  min-height: 100vh;
}

::-moz-selection {
  color: #fff;
  background-color: #525c65;
}

::selection {
  color: #fff;
  background-color: #1e8d44;
}

/* === MAIN === */
main {
  width: 70%;
  font-size: 130%;
  margin: 0px auto;
  line-height: 150%;
  padding: 2.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-heading);
  font-weight: 900;
  font-size: var(--font-size-heading);
  // text-transform: uppercase;
  margin: 25px 0px 15px 0px;
}
// h3 {
//   color: gray;
//   margin-bottom: 25px;
// }

p {
  font-family: var(--font-family-body);
  font-weight: 300;
}

.products {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .product {
    width: 320px;
    margin-left: 10px;
    padding: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    div {
      justify-content: center;
      display: flex;
      align-items: center;
      img {
        width: 75%;
      }
    }
  }
}

h2 {
  text-align: center;
  color: var(--color-primary);
  font-weight: 900;
}
.hero-title {
  font-size: 60px;
  text-shadow: 3px 3px 1px rgb(255, 200, 0);
}

.hero-text {
  animation: slide-left 1.75s ease;
  font-weight: 900 !important;
}
.hero-section img {
  max-width: 100%;
  animation: slide-right 1.75s ease;
}
/* about */
.text-content {
  background-color: rgba(30, 141, 68, 0.9);
  background: linear-gradient(
    45deg,
    rgba(4, 42, 43, 0.9) 0%,
    rgba(16, 198, 33, 0.9) 57%,
    rgba(253, 187, 45, 0.9) 97%
  );

  color: white;
  font-weight: 900;
  padding: 50px;
  border-radius: 8px;
  // border-bottom-right-radius: 25%;
}
/* about products */

.article-list-item {
  text-decoration: none;
  color: black;
  padding: 16px 0 0 0;
}
.about-section {
  min-height: 80vh;
}

.products-section {
  min-height: 80vh;
  font-weight: 300;
}
.product-images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.55);
  border-bottom-right-radius: 10%;
  img {
    width: 33%;
    margin: 10px;
  }
}
address a {
  color: white;
}
.story-section {
  img {
    max-width: 100%;
  }
}
.research-articles a {
  color: white;
  font-size: 16px;
}
.product-image {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(35deg);
}
.cities {
  display: flex;
  flex-wrap: wrap;
  margin-top:25px;
  .city {
    padding: 3px 15px;
    margin:10px 10px 10px 0px;
    cursor: pointer;
    color:var(--color-primary);
    font-weight: 600;
    // text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid var(--color-primary);
    border-radius: 30px;
    font-size:18px;
    &:hover {
      background-color: rgba(255, 255, 255, 1.0);
    }

  }
}
.product-address {
  color:var(--color-primary);
  .address {
    margin: 10px 0px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px 10px;
    border-radius: 10px;
    &:hover {
      background-color: rgba(255, 255, 255, 1.0);
    }
    h3 {
      font-size: 18px !important;
    }
    small {
      font-weight: 400 !important;
      color:#999;
    }
  }

}

@keyframes slide-right {
  0% {
    opacity: 0;
    right: -100;
    transform: translateX(80px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    left: -100;
    transform: translateX(-80px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  html,
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
  header {
    margin-bottom: 10px;
  }
  main {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }

  .copyright {
    font-size: 14px;
  }
  .hero-title {
    font-size: 22px;
  }
  .hero-image {
    max-width: 100%;
    padding: 0;
  }
  h2 {
    font-size: 22px;
  }
  p {
    font-size: 16px;
    text-align: left !important;
  }
  .text-content {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  .cities {
    flex-direction: column;
    justify-content: space-between;
    .city {
      display: flex;
      flex-direction: center;
      justify-content: center;
      align-items: center;
      padding: 10px 15px;
      margin:5px 0px;
      cursor: pointer;
      color:var(--color-primary);
      font-weight: 600;
      text-transform: uppercase;
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid var(--color-primary);
      border-radius: 30px;
      &:hover {
        background-color: rgba(255, 255, 255, 1.0);
      }
      span {
        font-size:18px;
      }
  
    }
  }
  .product-address {
    color:var(--color-primary);
    .address {
      margin: 5px 0px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 15px 10px;
      border-radius: 10px;
      &:hover {
        background-color: rgba(255, 255, 255, 1.0);
      }
      h3 {
        font-size: 16px !important;
      }
      small {
        font-weight: 400 !important;
        color:#999;
        font-size:14px;
      }
    }
  
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
